import React, { Component } from 'react';
import SectionTitle from '../../common/SectionTitle';

export default class AboutLeft extends Component {
  render() {
    return (
      <>
        <div className='col-lg-5'>
          <div className='about-item-box'>
            <SectionTitle title='Know More About Us' subtitle='More Information About ASE Group' />
            <div className='section-description padding-top-20px'>
              <p className='section__desc'>
                By fostering a positive workplace culture, investing in human resources, and
                implementing cutting-edge technology, we are also aiming to use expertise and
                contemporary technology to advance medical and pharmaceutical knowledge in the field
                of pharmaceutical marketing in the area.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
