import React, { Component } from 'react'

export default class Service3Midle extends Component {
    render() {
        return (
            <>
                <div className="col-lg-4 column-td-full">
                    <div className="info-img">
                        <img src="images/corona-symptom.png" alt="" />
                    </div>
                </div>
            </>
        )
    }
}
