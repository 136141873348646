import React, { Component } from 'react'
import Particles from 'react-particles-js';

export default class FooterParticle extends Component {
    render() {
        return (
            <div id="particles-bg">
                <Particles
                    params={{
                        "particles": {
                            "number": {
                                "value": 12,
                                "density": {
                                    "enable": true,
                                    "value_area": 900
                                }
                            },
                            "color": {
                                "value": ["#7E3CF9", "#F68A03", "#358FF7", "#38BB0C", "#3AAAE1"]
                            },
                            "shape": {
                                "type": "circle",
                                "stroke": {
                                    "width": 0
                                },
                                "polygon": {
                                    "nb_sides": 5
                                },
                                "image": {
                                    "src": "img/github.svg",
                                    "width": 100,
                                    "height": 100
                                }
                            },
                            "opacity": {
                                "value": 1,
                                "random": false,
                                "anim": {
                                    "enable": false,
                                    "speed": 8,
                                    "opacity_min": 1,
                                    "sync": false
                                }
                            },
                            "size": {
                                "value": 3,
                                "random": true,
                                "anim": {
                                    "enable": false,
                                    "speed": 80,
                                    "size_min": 1,
                                    "sync": false
                                }
                            },
                            "line_linked": {
                                "enable": false,
                                "distance": 150,
                                "color": "#4b4c5d",
                                "opacity": 1,
                                "width": 1
                            },
                            "move": {
                                "enable": true,
                                "speed": 3,
                                "direction": "top",
                                "random": false,
                                "straight": false,
                                "out_mode": "out",
                                "attract": {
                                    "enable": false,
                                    "rotateX": 600,
                                    "rotateY": 1200
                                }
                            }
                        },
                        "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                                "onhover": {
                                    "enable": false,
                                    "mode": "repulse"
                                },
                                "onclick": {
                                    "enable": true,
                                    "mode": "push"
                                },
                                "resize": true
                            },
                            "modes": {
                                "grab": {
                                    "distance": 400,
                                    "line_linked": {
                                        "opacity": 1
                                    }
                                },
                                "bubble": {
                                    "distance": 400,
                                    "size": 40,
                                    "duration": 2,
                                    "opacity": 8,
                                    "speed": 99
                                },
                                "repulse": {
                                    "distance": 200
                                },
                                "push": {
                                    "particles_nb": 4
                                },
                                "remove": {
                                    "particles_nb": 2
                                }
                            }
                        },
                        "retina_detect": true,
                        "config_demo": {
                            "hide_card": false,
                            "background_color": "#fff",
                            "background_image": "",
                            "background_position": "50% 50%",
                            "background_repeat": "no-repeat",
                            "background_size": "cover"
                        }
                    }}
                />
            </div>
        )
    }
}
