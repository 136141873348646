import React, { Component } from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import AboutFive from '../components/about/about5/AboutFive';
import AboutTwo from '../components/about/about2/AboutTwo';
import ServiceOne from '../components/services/service1/ServiceOne';
import SectionTitle from '../components/common/SectionTitle';
import Doctor from '../components/doctors/Doctor';
import Footer from '../components/common/Footer';
import ScrollTop from '../components/common/ScrollTop';

export default class OurServices extends Component {
  render() {
    return (
      <main className='about'>
        <Navbar />
        <Breadcrumb title='Our Services' />
        <section className='about-area about5 before-after-none padding-top-140px padding-bottom-120px'>
          {/* human resources development
Recruitment
Marketing studies
Medical knowledge and soft skills training
Pharmaceutical storage and billing
Financial collecting service
Event planning and event hall
 */}

          <div className='container mt-50'>
            <div className='row'>
              <div className='col-12'>
                <SectionTitle title='Human resources development' />
              </div>
              <div className='col-12'>
                <SectionTitle title='Recruitment' />
              </div>
              <div className='col-12'>
                <SectionTitle title='Marketing studies' />
              </div>
              <div className='col-12'>
                <SectionTitle title='Medical knowledge and soft skills training' />
              </div>
              <div className='col-12'>
                <SectionTitle title='Pharmaceutical storage and billing' />
              </div>
              <div className='col-12'>
                <SectionTitle title='Financial collecting service' />
              </div>
              <div className='col-12'>
                <SectionTitle title='Event planning and event hall' />
              </div>
            </div>
          </div>
        </section>
        <ScrollTop />
        <Footer />
      </main>
    );
  }
}
