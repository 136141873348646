import React, { Component } from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import AboutFive from '../components/about/about5/AboutFive';
import AboutTwo from '../components/about/about2/AboutTwo';
import ServiceOne from '../components/services/service1/ServiceOne';
import SectionTitle from '../components/common/SectionTitle';
import Doctor from '../components/doctors/Doctor';
import Footer from '../components/common/Footer';
import ScrollTop from '../components/common/ScrollTop';

export default class Partner extends Component {
  render() {
    return (
      <main className='about'>
        <Navbar />
        <Breadcrumb title='Partner' />
        <section className='about-area about5 before-after-none padding-top-140px padding-bottom-120px'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <form className='partner-form'>
                  <h2 className='form-title'>المعلومات الشخصية</h2>
                  <div className='form-group'>
                    <label>الاسم</label>
                    <input type='text' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>ايميل</label>
                    <input type='email' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>رقم هاتف</label>
                    <input type='tel' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>ايميل</label>
                    <input type='email' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>مواليد</label>
                    <input type='date' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>منطقة السكن محافظة وقضاء</label>
                    <input type='text' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>الجنس </label>
                    <select className='form-control'>
                      <option value=''>اختر</option>
                      <option value='male'>ذكر</option>
                      <option value='female'>أنثى</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label>الحالة الزوجية</label>
                    <select className='form-control'>
                      <option value=''>اختر</option>
                      <option value='single'>اعزب</option>
                      <option value='married'>متزوج</option>
                      <option value='separated'>منفصل</option>
                      <option value='widowed'>ارمل</option>
                      <option value='prefer_not_to_disclose'>لا أرغب بالافصاح</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label>امتلاك سيارة</label>
                    <select className='form-control'>
                      <option value=''>اختر</option>
                      <option value='yes'>نعم</option>
                      <option value='no'>لا</option>
                      <option value='other'>امتلك وسيلة نقل أخرى</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label>صورة شخصية </label>
                    <input type='file' className='form-control-file' />
                  </div>
                  <h2 className='form-title'>التحصيل التدراسي</h2>
                  <div className='form-group'>
                    <label>التحصيل الدراسي</label>
                    <select className='form-control'>
                      <option value=''>اختر</option>
                      <option value='pharmacy'>صيدلة</option>
                      <option value='medicine'>طب</option>
                      <option value='dentistry'>طب اسنان</option>
                      <option value='veterinary_medicine'>طب بيطري</option>
                      <option value='science'>علوم</option>
                      <option value='accounting'>محاسبة</option>
                      <option value='information_technology'>تكنولوجيا معلومات</option>
                      <option value='student'>طالب</option>
                      <option value='other'>أخرى</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label>الجامعة او الكلية</label>
                    <input type='text' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>تاريخ التخرج</label>
                    <input type='date' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>شهادات اخرى</label>
                    <input type='text' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>شهادات عليا</label>
                    <input type='text' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <label>صورة هوية النقابة او هوية الجامعة</label>
                    <input type='file' className='form-control-file' />
                  </div>
                  <h2 className='form-title'>الخبرات الوظيفية</h2>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>ملاحظات</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label> مدة العمل</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label> العنوان الظيفي</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label> اسم الشركة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>ملاحظات</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label> مدة العمل</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label> العنوان الظيفي</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label> اسم الشركة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>ملاحظات</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>مدة العمل</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label> العنوان الظيفي</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label> اسم الشركة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <h2 className='form-title'>التدريب والتطوير</h2>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>مدتها</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>الدولة او المدينة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>الجهة المانحة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>اسم الدورة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>مدتها</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>الدولة او المدينة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>الجهة المانحة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>اسم الدورة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label>مدتها</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>الدولة او المدينة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>الجهة المانحة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-3'>
                        <label>اسم الدورة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <h2 className='form-title'>اللغات</h2>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <label>اللغة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-4'>
                        <label>درجة اجادة القراءة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-4'>
                        <label>درجة اجادة الكتابة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <label>اللغة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-4'>
                        <label>درجة اجادة القراءة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-4'>
                        <label>درجة اجادة الكتابة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <label>اللغة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-4'>
                        <label>درجة اجادة القراءة</label>
                        <input type='text' className='form-control' />
                      </div>
                      <div className='col-md-4'>
                        <label>درجة اجادة الكتابة</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='text-center'>
                    <button className='btn btn-primary' type='submit'>
                      ارسال
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <ScrollTop />
        <Footer />
      </main>
    );
  }
}
