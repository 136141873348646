import React, { Component } from 'react';
import SectionTitle from '../../common/SectionTitle';

export default class About5Right extends Component {
  render() {
    return (
      <>
        <div className='col-lg-6'>
          <div className='section-content-box pl-5'>
            <SectionTitle title='Who are we'>
              <h4 className='widget-title pt-4 pb-3'>ASE Group</h4>
            </SectionTitle>
            <div className='section-description'>
              <p className='section__desc mb-2'>
                As an agent for numerous global corporations, including British, Egyptian, Turkish,
                and others that specialize in the production of pharmaceuticals, cosmetics, and
                medical supplies, we at ASE Group. Currently, we are working in Iraq and the Middle
                East to meet the needs of the population and the pharmaceutical market for
                pharmaceuticals by delivering efficient and dependable pharmaceuticals and
                delivering accurate scientific and economic information for health care providers
                like doctors, pharmacists, and others by adhering to the true role of the medical
                representative.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
