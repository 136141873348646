import React, { Component } from 'react'
import HowItWork2Items from './HowItWork2Items'

export default class HowItWork2 extends Component {
    render() {
        return (
            <div className="container">
                <div className="row mt-5">
                    <HowItWork2Items />
                </div>
            </div>
        )
    }
}
