import React, { Component } from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import AboutFive from '../components/about/about5/AboutFive';
import AboutTwo from '../components/about/about2/AboutTwo';
import ServiceOne from '../components/services/service1/ServiceOne';
import SectionTitle from '../components/common/SectionTitle';
import Doctor from '../components/doctors/Doctor';
import Footer from '../components/common/Footer';
import ScrollTop from '../components/common/ScrollTop';

export default class Career extends Component {
  render() {
    return (
      <main className='about'>
        <Navbar />
        <Breadcrumb title='Career' />
        <section className='about-area about5 before-after-none padding-top-140px padding-bottom-120px'>
          <div className='container mt-50'>
            <div className='row'>
              <div className='col-12'>
                <div className='section-description'>
                  <p className='section__desc mb-2'>
                    We at ASE GROUP work to ensure that our employees are women and men with a high
                    level of skills and experience. We also welcome fresh graduates who do not have
                    experience. Everyone will attend professional training befitting the company's
                    status and the value of its team. Do not hesitate to apply for the job if you
                    meet the qualifications required for this job.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ScrollTop />
        <Footer />
      </main>
    );
  }
}
